<div [formGroup]="filterForm" class="filter-dialog-container">
    <span class="text-muted">
        Filter through specific criterias to find the most accurate results
    </span>
    <div class="d-flex w-100 mt-4">
        <app-g2g-text-input class="w-100" [formname]="filterForm" type="text" formcontrolname="title"
            placeholder="Post Title"></app-g2g-text-input>
    </div>
    <div class="d-flex w-100">
        <app-g2g-dropdown class="flex-fill" [options]="categories" displayLabel="name" value="id"
            [formname]="filterForm" formcontrolname="category" placeholder="Post Category"></app-g2g-dropdown>
    </div>
    <div class="d-flex w-100">
        <p-calendar [inline]="true" formControlName="range" selectionMode="range" [readonlyInput]="true" />
    </div>
    <div class="w-100 gap-2 d-flex align-items-center justify-content-end">
        <p-button [text]="true" severity="secondary" (click)="closeDialog()" label="Cancel" />
        <p-button (click)="onSubmit()" [disabled]="!filterForm.valid" label="Apply" />
    </div>

</div>