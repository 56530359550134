<div [formGroup]="filterForm" class="filter-dialog-container">
    <span class="text-muted">
        Filter through specific criterias to find the most accurate results
    </span>
    <div class="g2g-autocomplete-container w-100 mt-4">
        <app-g2g-text-input class="w-100" [formname]="filterForm" type="text" formcontrolname="location"
            placeholder="Location"></app-g2g-text-input>
    </div>
    <div class="d-flex w-100">
        <app-g2g-dropdown class="flex-fill" [options]="position" displayLabel="name" value="id" [formname]="filterForm"
            formcontrolname="position" placeholder="Field Position"></app-g2g-dropdown>
    </div>
    <div class="d-flex w-100">
        <app-g2g-dropdown class="flex-fill" [options]="dominantFoot" displayLabel="name" value="id"
            [formname]="filterForm" formcontrolname="dominantFoot" placeholder="Dominant Foot"></app-g2g-dropdown>
    </div>
    <div class="age-range mb-2 d-flex gap-10 flex-column">
        <label>Age Range</label> <br>
        <div class="px-2">
            <p-slider min="5" max="30" step="5" formControlName="rangeValues" [range]="true" styleClass="w-14rem" />
        </div>
        <div class="d-flex mt-2 w-100 align-items-center justify-content-between">
            <small>
                5
            </small>
            <small>
                30
            </small>
        </div>
        <div class="text-center">
            Min: <span class="text-gold font-bold">{{getMin()}}</span>,
            Max: <span class="text-gold font-bold">{{getMax()}}</span>
        </div>
    </div>
    <div class="w-100 gap-2 d-flex align-items-center justify-content-end">
        <p-button [text]="true" severity="secondary" (click)="closeDialog()" label="Cancel" />
        <p-button (click)="onSubmit()" [disabled]="!filterForm.valid" label="Apply" />
    </div>

</div>