import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { G2gDropdownComponent } from '../../form/g2g-dropdown/g2g-dropdown.component';
import { ProfileService } from '../../../services/profile.service ';
import { SliderModule } from 'primeng/slider';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { IUserFilter } from '../../../models/user-filter';
import { G2gTextInputComponent } from '../../form/g2g-text-input/g2g-text-input.component';

export interface ICountry {
  name: string,
  code: string
}

@Component({
  selector: 'app-filter-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    AutoCompleteModule,
    G2gDropdownComponent,
    SliderModule,
    ButtonModule,
    G2gTextInputComponent
  ],
  templateUrl: './filter-user-dialog.component.html',
  styleUrl: './filter-user-dialog.component.scss',
  providers: []
})
export class FilterUserDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  filterForm!: FormGroup;
  public filteredCountries!: ICountry[];
  private $unsubscribe = new Subject<void>();
  public position: any[] = [];
  public dominantFoot: any[] = [];

  constructor(
    private _profileService: ProfileService,
    private ref: DynamicDialogRef,
  ) { }
  ngOnInit(): void {

    this.getAllEnums();

    this.filterForm = new FormGroup({
      location: new FormControl(null),
      position: new FormControl(null),
      dominantFoot: new FormControl(null),
      rangeValues: new FormControl([5, 15]),
      minAge: new FormControl(5),
      maxAge: new FormControl(15),
    });

    this.filterForm.get('minAge')?.setValue(this.getMin());
    this.filterForm.get('maxAge')?.setValue(this.getMax());

  }

  getAllEnums() {
    this._profileService.getAllEnums()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.dominantFoot = res?.dominantFoot || [];
        this.position = res?.position || [];
      });
  }

  getMin() {
    let range = this.filterForm.get('rangeValues')?.value;
    return range[0];
  }
  getMax() {
    let range = this.filterForm.get('rangeValues')?.value;
    return range[1];
  }

  hasError(formcontrolname: string, validation: string) {
    if (this.filterForm && formcontrolname) {
      return this.filterForm.get(formcontrolname)?.hasError(validation) && this.filterForm.get(formcontrolname)?.touched;
    } else {
      return false;
    }
  }

  closeDialog() {
    this.ref.close();
  }

  onSubmit() {
    const filter: IUserFilter = {
      location: this.filterForm.get('location')?.value || null,
      position: this.filterForm.get('position')?.value || null,
      dominantFoot: this.filterForm.get('dominantFoot')?.value || null,
      minAge: this.filterForm.get('minAge')?.value || null,
      maxAge: this.filterForm.get('maxAge')?.value || null,
    }
    this.ref.close({search: "user", filter});
  }
  ngAfterViewInit(): void {
    this.filterForm.get('rangeValues')?.valueChanges.subscribe((minMax: number[]) => {
      this.filterForm.get('minAge')?.setValue(minMax[0]);
      this.filterForm.get('maxAge')?.setValue(minMax[1]);
    })
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
